import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Showcasing all sports, equally`}</h1>
    <p>{`I'm so bummed that we weren't able to have sports earlier this year! But I'm double excited that seasons are starting and teams are starting to play! Something i campaigned for heavily last year, while running for Junior Class President, is to have equal representation of all sports throughout the year. Obviously, that wasn't as possible because there weren't any games at the beginning of the year and only certain sports will be having livestreams.`}</p>
    <p>{`We’re moving into a direction where I'm hopeful about sports for next year and I want to make sure lacrosse is advertised just as much football is and golf to be showcased at the same level as basketball. There are so many underrepresented groups on campus like dance, color guard, marching band, and drumline which all deserve more recognition!`}</p>
    <p>{`I asked this in another post, but did you know that Irvine High created an eSports team? And we've already won tournaments? Isn't that fascinating? I want people to be excited about eSports and cheer the team on, especially since it's new and will benefit from the spotlight.`}</p>
    <p>{`Something my council is currently doing is getting juniors from each sport that is playing to send small clips of themselves before or after a game, to cheer them on using social media. I'm sure we'll have more information next year what the exact schedule is for each sport, including how much interaction non-players can have with the team.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      